import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["allDay", "singleDayFields", "multiDayFields"];
  static values = {
    hiddenClass: {
      type: String,
      default: "hidden"
    },
    visibleClass: {
      type: String,
      default: ""
    }
  };

  connect() {
    this.toggle();
  }

  toggle() {
    const allDay = this.allDayTarget.checked;

    this.setClass(this.singleDayFieldsTarget, allDay);
    this.setClass(this.multiDayFieldsTarget, !allDay);

    this.toggleFields(this.singleDayFieldsTarget, !allDay);
    this.toggleFields(this.multiDayFieldsTarget, allDay);
  }

  setClass(element, isHidden) {
    element.className = isHidden ? this.hiddenClassValue : this.visibleClassValue;
  }

  toggleFields(container, isEnabled) {
    const fields = container.querySelectorAll("input,select");
    fields.forEach((input) => {
      input.disabled = !isEnabled;
      input.required = isEnabled;
    });
  }
}
