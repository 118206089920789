import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="flash"
export default class extends Controller {
  static values = {
    dismissAfter: { type: Number, default: null }
  };

  connect() {
    this.showTimeout = setTimeout(() => {
      this.show();
    }, 100);

    if (this.dismissAfterValue != null && this.dismissAfterValue > 0) {
      this.dismissTimeout = setTimeout(() => {
        this.close();
      }, this.dismissAfterValue);
    }
  }

  disconnect() {
    clearTimeout(this.showTimeout);
    clearTimeout(this.dismissTimeout);
  }

  close() {
    leave(this.element).then(() => {
      this.element.remove();
      this.#hideWrapper();
    });
  }

  show() {
    this.#showWrapper();
    enter(this.element);
  }

  #hideWrapper() {
    this.#flashWrapper.classList.remove("flex");
    this.#flashWrapper.classList.add("hidden");
  }

  #showWrapper() {
    this.#flashWrapper.classList.remove("hidden");
    this.#flashWrapper.classList.add("flex");
  }

  get #flashWrapper() {
    return document.getElementById("flash-wrapper");
  }
}
