import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["number", "issueDate", "saleDate", "dueDate"];
  static values = {
    newRecord: Boolean
  };

  handleDateChange() {
    if (this.issueDateTarget.value === "") {
      return;
    }

    this.saleDateTarget.value = this.issueDateTarget.value;
    this.dueDateTarget.value = this.addDays(this.issueDateTarget.value, 14);

    if (!this.newRecordValue) {
      return;
    }

    get(`/invoices/generate_number?date=${this.issueDateTarget.value}`, {
      responseKind: "turbo-stream"
    });
  }

  addDays(date, days) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const result = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    return result.toISOString().split("T")[0];
  }
}
