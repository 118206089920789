import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import debounce from "debounce";

export default class extends Controller {
  static targets = ["container", "backdrop", "content", "form", "input"];

  connect() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300);
    this.turboFrame = this.element.closest("turbo-frame");
    this.showModal();
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  debouncedSubmit() {
    this.submit();
  }

  showModal() {
    enter(this.backdropTarget);
    enter(this.contentTarget);
  }

  hideModal() {
    leave(this.contentTarget);
    leave(this.backdropTarget).then(() => {
      this.turboFrame.removeAttribute("src");
      this.containerTarget.remove();
    });
  }

  outsideModalClicked(e) {
    const clickedInsideModal = this.contentTarget.contains(e.target) || this.contentTarget == e.target;

    if (!clickedInsideModal) this.hideModal();
  }

  closeWithKeyboard(e) {
    if (e.code === "Escape") this.hideModal();
  }
}
