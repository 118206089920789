import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Connects to data-controller="bar-chart"
export default class extends Controller {
  static targets = ["chart"];

  static values = {
    series: Array,
    colors: Array,
    height: {
      type: Number,
      default: 400
    }
  };

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }

  get chartOptions() {
    return {
      chart: {
        type: "bar",
        height: this.heightValue,
        fontFamily: "Inter, sans-serif",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          borderRadiusApplication: "end",
          borderRadius: 8
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontFamily: "Inter, sans-serif"
        }
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 1
          }
        }
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"]
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        tickAmount: 8,
        floating: false,
        labels: {
          rotate: 0,
          show: true,
          style: {
            fontSize: "13px",
            fontFamily: "Inter, sans-serif"
          },
          offsetX: -2
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          show: false
        }
      },
      yaxis: {
        labels: {
          align: "left",
          minWidth: 0,
          maxWidth: 140,
          style: {
            fontSize: "13px",
            fontFamily: "Inter, sans-serif"
          }
        }
      },
      fill: {
        opacity: 1
      },
      series: this.seriesValue,
      colors: this.colorsValue
    };
  }
}
