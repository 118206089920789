import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  static targets = ["form", "list"];

  connect() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300);
  }

  submit() {
    this.listTarget.scrollTo({ top: 0, behavior: "auto" });
    this.formTarget.requestSubmit();
  }

  debouncedSubmit() {
    this.submit();
  }
}
