import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {
  initialize() {
    this.debouncedSubmit = debounce(this.debouncedSubmit.bind(this), 300);
  }

  submit() {
    this.element.requestSubmit();
  }

  debouncedSubmit() {
    this.submit();
  }

  sanitizeFormData(event) {
    const formData = event.formData;
    for (const [name, value] of Array.from(formData.entries())) {
      if (value === "") formData.delete(name);
    }
    return formData;
  }

  removeFilter({ detail: { content } }) {
    const input = this.element.querySelector(`#${content}`);
    if (input) {
      input.value = "";
      this.submit();
    }
  }

  removeAllFilters() {
    this.element.querySelectorAll("input:not([type='hidden']),select").forEach((input) => {
      input.value = "";
    });
    this.submit();
  }

  openModal(event) {
    event.preventDefault();
    const searchParams = new URLSearchParams(window.location.search);
    const url = `${event.target.href}?${searchParams.toString()}`;
    const frame = document.querySelector("#modal");
    frame.src = url;
    frame.reload();
  }
}
