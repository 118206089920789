import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["container", "backdrop", "content"];
  static values = {
    hideOnSubmitEnd: { type: Boolean, default: true }
  };

  connect() {
    this.showModal();
    this.turboFrame = this.element.closest("turbo-frame");
  }

  showModal() {
    enter(this.backdropTarget);
    enter(this.contentTarget);
  }

  hideModal() {
    leave(this.contentTarget);
    leave(this.backdropTarget).then(() => {
      this.turboFrame.removeAttribute("src");
      this.containerTarget.remove();
    });
  }

  submitEnd(e) {
    if (e.detail.success && this.hideOnSubmitEndValue) this.hideModal();
  }

  outsideModalClicked(e) {
    const clickedInsideModal = this.contentTarget.contains(e.target) || this.contentTarget == e.target;

    if (!clickedInsideModal) this.hideModal();
  }

  closeWithKeyboard(e) {
    if (e.code === "Escape") this.hideModal();
  }
}
