import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

// Connects to data-controller="imask"
export default class extends Controller {
  static values = {
    formatter: String,
    options: Object
  };

  connect() {
    this.mask = IMask(this.element, this.#maskOptions);
  }

  disconnect() {
    this.mask?.destroy();
  }

  get #maskOptions() {
    if (this.formatterValue === "number")
      return {
        mask: Number,
        autofix: true,
        ...this.optionsValue
      };
    else
      return {
        mask: this.formatterValue
      };
  }
}
