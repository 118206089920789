import "@hotwired/turbo-rails";
import "~/controllers";
import "~/turbo_addons";
import "~/libs/pwa-install.bundle";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", async () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(function (registration) {
        console.log("Service Worker registered with scope:", registration.scope);
      })
      .catch(function (error) {
        console.error("Service Worker registration failed:", error);
      });
  });
}

document.addEventListener("turbo:frame-load", (event) => {
  if (event.target.hasAttribute("data-autoscroll-top")) {
    window.scrollTo({ top: 0, behavior: "auto" });
  }
});

// turbo-loading
// https://turbo.hotwired.dev/reference/events
document.addEventListener("turbo:before-fetch-request", () => {
  document.body.classList.add("turbo-loading");
});
document.addEventListener("turbo:before-fetch-response", () => {
  document.body.classList.remove("turbo-loading");
});
document.addEventListener("turbo:fetch-request-error", () => {
  document.body.classList.remove("turbo-loading");
});
