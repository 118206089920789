import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Connects to data-controller="line-chart"
export default class extends Controller {
  static targets = ["chart"];

  static values = {
    series: Array,
    categories: Array,
    height: {
      type: Number,
      default: 350
    }
  };

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }

  get chartOptions() {
    return {
      chart: {
        height: this.heightValue,
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      tooltip: {
        enabled: true,
        x: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2,
        curve: "straight"
      },
      grid: {
        strokeDashArray: 4
      },
      fill: {
        type: "gradient",
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
          opacityFrom: 0.1,
          opacityTo: 0.8
        }
      },
      series: this.seriesValue,
      legend: {
        show: false
      },
      xaxis: {
        categories: this.categoriesValue,
        tickAmount: this.categoriesValue.length > 7 ? 7 : this.categoriesValue.length,
        labels: {
          rotate: 0,
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal bg-white"
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          stroke: {
            dashArray: 0
          },
          dropShadow: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        }
      }
    };
  }
}
