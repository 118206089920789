import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Connects to data-controller="pie-chart"
export default class extends Controller {
  static targets = ["chart"];

  static values = {
    labels: Array,
    series: Array,
    colors: Array,
    height: {
      type: Number,
      default: 400
    }
  };

  connect() {
    this.chart = new ApexCharts(this.chartTarget, this.chartOptions);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }

  get chartOptions() {
    return {
      chart: {
        height: this.heightValue,
        width: "100%",
        type: "pie"
      },
      stroke: {
        colors: ["white"],
        lineCap: ""
      },
      plotOptions: {
        pie: {
          labels: {
            show: true
          },
          size: "100%",
          dataLabels: {
            offset: -25
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Inter, sans-serif"
        }
      },
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif"
      },
      xaxis: {
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      colors: this.colorsValue,
      series: this.seriesValue,
      labels: this.labelsValue
    };
  }
}
